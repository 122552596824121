.gm-grad {
  background: hsla(21, 100%, 94%, 0.9);
  background: linear-gradient(282deg, hsla(21, 100%, 94%, 0.9) 0%, rgb(220, 168, 142) 100%);
  background: -moz-linear-gradient(282deg, hsla(21, 100%, 94%, 0.9) 0%, rgb(220, 168, 142) 100%);
  background: -webkit-linear-gradient(282deg, hsla(21, 100%, 94%, 0.9) 0%, rgb(220, 168, 142) 100%);
}

.gm-grad-success {
  background: hsl(109deg 100% 94% / 90%);
  background: linear-gradient(282deg, hsl(109deg 100% 94% / 90%) 0%, hsl(126, 34%, 69%) 100%);
  background: -moz-linear-gradient(282deg, hsl(109deg 100% 94% / 90%) 0%, hsl(126, 34%, 69%) 100%);
  background: -webkit-linear-gradient(282deg, hsl(109deg 100% 94% / 90%) 0%, hsl(126, 34%, 69%) 100%);
}

.gm-grad-error {
  background: #ffdedee6;
  background: linear-gradient(282deg, #ffdedee6 0%, #ffa0a0 100%);
  background: -moz-linear-gradient(282deg, #ffdedee6 0%, #ffa0a0 100%);
  background: -webkit-linear-gradient(282deg, #ffdedee6 0%, #ffa0a0 100%);
}

.spinLoader-reverse {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
  animation-direction: reverse;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}
