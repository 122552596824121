* {
  margin: 0px;
  padding: 0px;
}
html {
  height  : 100%;
  overflow: hidden;
  position: relative;
  font-size: 16px;
}
body {
  font-family: 'Rubik', sans-serif;
  height  : 100%;
  /* overflow: auto; */
  position: relative;
  accent-color: #c38464;
}
iframe{
  pointer-events: none;
}
iframe#msdoc-iframe{
  pointer-events: auto;
}
iframe#miro-iframe{
  pointer-events: auto;
}
iframe#GUIDDE-JS-EXT-REC{
  pointer-events: auto;
}
.App {
  text-align: center;
}
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.t4e{
  transition: all 0.4s;
}
.t1e{
  transition: all 0.1s;
}
.navOpen{
  left: 15.625rem;
  width:calc(100% - 15.625rem);
}
.navClose{
  left: 4rem;
  width:calc(100% - 4rem);
}
.ofC {
  object-fit: cover;
}
.ofCo {
  object-fit: contain;
}
.opC {
  object-position: center;
}
.opT {
  object-position: top;
}
.ffMs {
  font-family: 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
}
.ffCl {
  font-family: 'calibri','Montserrat', Roboto, "Helvetica Neue", sans-serif;
}
.ffR
 {
  font-family: 'Rubik';
}
.bsGm{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.bsM{
  box-shadow: 0px 3px 16px #0000000D;
}
.bsCard2 {
  box-shadow: 0 3px 16px rgb(0 0 0 / 5%);
}
.disable_resize_textarea{
  resize: none;
}

.potable {
  border: 1px solid #E6E6E6;
  padding: 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.bdtlr0{border-top-left-radius: 0px;}
.bdtrr0{border-top-right-radius: 0px;}
.bdblr0{border-bottom-left-radius: 0px;}
.bdbrr0{border-bottom-right-radius: 0px;}

.fadeIn{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration:0.4s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeIn {0% {opacity: 0; transform: translate3d(0,3.125rem,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeIn {0% {opacity: 0; transform: translate3d(0,3.125rem,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.fadeInOutOnce{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInOut;
  animation-duration:6s;
  animation-fill-mode:forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}


.fadeOut{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeOut;
  animation-duration:4s;
  animation-fill-mode:both;
  /* animation-iteration-count: 1; */
}
@-webkit-keyframes fadeOut {0% {opacity: 1; transform: translate3d(0,0,0);} to {opacity: 0; transform: translate3d(0,3.125rem,0);} }
@keyframes fadeOut {0% {opacity: 1; transform: translate3d(0,0,0)} to {opacity: 0; transform: translate3d(0,3.125rem,0);} } 

.fadeInLeft{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInLeft;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInLeft {0% {opacity: 0; transform: translate3d(-50%,0,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeInLeft {0% {opacity: 0; transform: translate3d(-50%,0,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.fadeInRight{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInRight;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInRight {0% {opacity: 0; transform: translate3d(50%,0,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeInRight {0% {opacity: 0; transform: translate3d(50%,0,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.fadeInUp{
  animation-name: fadeInUp;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInUp {0% {opacity: 0; transform: translate3d(0,50%,0); } to {opacity: 1; transform: none} }
@keyframes fadeInUp {0% {opacity: 0; transform: translate3d(0,50%,0);} to {opacity: 1; transform: none;} } 

.fadeInDown{
  animation-name: fadeInDown;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInDown {0% {opacity: 0; transform: translate3d(0,-50%,0); } to {opacity: 1; transform: none} }
@keyframes fadeInDown {0% {opacity: 0; transform: translate3d(0,-50%,0);} to {opacity: 1; transform: none;} } 



.ffRw{flex-flow: row wrap}

.wsNpar > div.errCss {white-space: nowrap; overflow: hidden;}
.trnsRR{transform: rotate(180deg)}
.errorHeight {height: 0.75rem;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
select:focus{
  outline: none;
}

.ghost-card-pulsate-gray{
  background: linear-gradient(-45deg, #EEEEEE, #DDDDDD, #EEEEEE, #DDDDDD);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

.ghost-card-pulsate{
  background: linear-gradient(-45deg, #DDDDDD, rgba(195, 132, 100, 0.3), #DDDDDD, rgba(195, 132, 100, 0.3));
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

.offlineGradient {
  background: linear-gradient(-45deg, #999, rgba(3, 19, 1, 0.5), #000, rgba(3, 19, 1, 0.5));
  background-size: 400% 400%;
	-webkit-animation: Gradient 2.25s ease infinite;
	-moz-animation: Gradient 2.25s ease infinite;
	animation: Gradient 2.25s ease infinite;
}
@-webkit-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }
@-moz-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } } 
@keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }

.ScaleFadeOut{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: ScaleFadeOut;
  animation-duration:1s;
  animation-fill-mode:both;
}

@-webkit-keyframes ScaleFadeOut {0% {opacity: 1; -webkit-transform: scale(1.0); display: block;} to {opacity: 0; -webkit-transform: scale(50.0); display: none;} }
@keyframes ScaleFadeOut {0% {opacity: 1; transform: scale(1.0); display: block;} to {opacity: 0; transform: scale(50.0); display: none;} } 

.fadeInOut{
  -webkit-animation-name: fadeInOut;
  animation-name: fadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode:both;
}

@-webkit-keyframes fadeInOut {0% {opacity: 0; } 50% {opacity: 1; } 100% {opacity: 0;} }
@keyframes fadeInOut {0% {opacity: 0; } 50% {opacity: 1; } 100% {opacity: 0;} }

.backdrop {
  background-color: rgba(0,0,0,0.4);
}
.hA{
  height: auto;
}
.wA{
  width: auto;
}
.sideDrawerNav {
  transform: translateX(-150%);
  transition: transform 0.2s ease-in-out;
}

.sideDrawerNav.open {
  transform: translateX(0);
}
.dollarPrefix input{
  padding-left: 1.5rem;  
}
.dollarPrefix::after{
  content: "$";
    position: absolute;
    font-size: 0.75rem;
    color: #333;
    top: 3.125rem;
    left: 1.25rem;
}
.trns0{-o-transform:none;-ms-transform:none;-moz-transform:none;-webkit-transform:none;transform:none}
.trnsU{-o-transform:translateY(-200%);-ms-transform:translateY(-200%);-moz-transform:translateY(-200%);-webkit-transform:translateY(-200%);transform:translateY(-200%)}
.trnsD{-o-transform:translateY(200%);-ms-transform:translateY(200%);-moz-transform:translateY(200%);-webkit-transform:translateY(200%);transform:translateY(200%)}          
.trnsR{-o-transform:translateX(200%);-ms-transform:translateX(200%);-moz-transform:translateX(200%);-webkit-transform:translateX(200%);transform:translateX(200%)}
.trnsL{-o-transform:translateX(-200%);-ms-transform:translateX(-200%);-moz-transform:translateX(-200%);-webkit-transform:translateX(-200%);transform:translateX(-200%)}                
/* heartbeat */
@keyframes animateHeart {0% {transform: scale(0.8); } 5% {transform: scale(0.9); } 10% {transform:  scale(1); } 15% {transform:  scale(1.2); } 50% {transform: scale(0.8); } 100% {transform: scale(1); } } 
.heartBeat{ animation: animateHeart 1.2s; animation-iteration-count: 1;}

.arrowMove {
  -webkit-animation-name: arrowMove;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: arrowMove;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-webkit-keyframes arrowMove {0% {top: 0%;} 33% {top: 100%;} 100% {top: 100%;}}
@keyframes arrowMove {0% {top: 0%;} 33% {top: 100%;} 100% {top: 100%;}}


.arrowMoveRightTruck {
  -webkit-animation-name: arrowMoveTruck;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: arrowMoveTruck;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: -100%;
}
@keyframes arrowMoveTruck{
  0%{left:-40%;bottom:-5px;transform: rotate(-0deg);} 
  20%{left:0%;bottom:-5px;transform: rotate(-0deg);}  
  30% {left:6%;bottom:-5px;transform: rotate(-14deg);} 
  40%{left:20%;bottom:-5px;transform: rotate(-14deg);} 
  42%{left:29%;bottom:-2px;transform: rotate(0deg);} 
  55%{left: 49%; bottom: -5px; transform: rotate(18deg);} 
  60% {left: 58%; bottom: -5px; transform: rotate(18deg);} 
  62% {left: 61%; bottom: -5px; transform: rotate(7deg);} 
  67% {left: 66%; bottom: -5px; transform: rotate(0deg);} 
  100% {left:120%;bottom:-5px;transform: rotate(0deg);}
}

@-webkit-keyframes arrowMoveTruck {
  0%{left:-40%;bottom:-5px;transform: rotate(-0deg);} 
  20%{left:0%;bottom:-5px;transform: rotate(-0deg);}  
  30% {left:6%;bottom:-5px;transform: rotate(-14deg);} 
  40%{left:20%;bottom:-5px;transform: rotate(-14deg);} 
  42%{left:29%;bottom:-2px;transform: rotate(0deg);} 
  55%{left: 49%; bottom: -5px; transform: rotate(18deg);} 
  60% {left: 58%; bottom: -5px; transform: rotate(18deg);} 
  62% {left: 61%; bottom: -5px; transform: rotate(7deg);} 
  67% {left: 66%; bottom: -5px; transform: rotate(0deg);} 
  100% {left:120%;bottom:-5px;transform: rotate(0deg);}
}

.arrowMoveRightShip{
  -webkit-animation-name: arrowMoveRight;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: arrowMoveRight;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: -100%;
  bottom: -10%;
}
@keyframes arrowMoveShip{
  0%{left:-40%;bottom:-8%;transform: rotate(-0deg);}
  10%{left:-10%;bottom:-8%;transform: rotate(10deg);} 
  20%{left:20%;bottom:-8%;transform: rotate(-10deg);} 
  30%{left:50%;bottom:-8%;transform: rotate(10deg);} 
  40%{left:70%;bottom:-8%;transform: rotate(-10deg);} 
  50%{left:90%;bottom:-8%;transform: rotate(10deg);} 
  60%{left:110%;bottom:-8%;transform: rotate(-10deg);} 
  70%{left:130%;bottom:-8%;transform: rotate(10deg);} 
  80%{left:150%;bottom:-8%;transform: rotate(-10deg);} 
  100%{left:170%;bottom:-8%;transform: rotate(-0deg);} 
 
}

@-webkit-keyframes arrowMoveShip {
  0%{left:-40%;bottom:-8%;transform: rotate(-0deg);}
  10%{left:-10%;bottom:-8%;transform: rotate(10deg);} 
  20%{left:20%;bottom:-8%;transform: rotate(-10deg);} 
  100%{left:120%;bottom:-8%;transform: rotate(-0deg);} 
}


.arrowMoveRightAirPlane {
  -webkit-animation-name: arrowMoveRight;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: arrowMoveRight;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: -100%;
}


@-webkit-keyframes arrowMoveRight {0% {left: -100%;} 50% {left: 120%;}  100% {left: 250%;}}
@keyframes arrowMoveRight{0% {left: -100%;} 50% {left: 120%;}  100% {left: 250%;}}

.arrowMoveRightMobile{
  -webkit-animation-name: arrowMoveRightMobile;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: arrowMoveRight;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: 0%;
}

@-webkit-keyframes arrowMoveRightMobile {0%,100% {left: 0%;} 10%{left: 20%;} 20%{left: 40%;} 30%{left:60%} 40% {left: 80%;} 50% {left: 100%;}}
@keyframes arrowMoveRightMobile{0%,100% {left: 0%;} 10%{left: 20%;} 20%{left: 40%;} 30%{left:60%} 40% {left: 80%;} 50% {left: 100%;}}

.navClose.mobile{
  left: 0px;
  width:100%;
}

@media (hover: none) {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
}



div.hideScrollBar::-webkit-scrollbar {
  display: none;
  /* width: 7px; */
}

div.hideScrollBar::-webkit-scrollbar-thumb {
  display: none;
  /* background-color: #b3b3b4; */
  
}

@media only screen and (max-width: 1300px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 600px) {

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.5);
    border-radius: 8px;
    opacity: 0.5;
  }
}


#createOrder .react-date-picker__wrapper, #LogisticForm  .react-date-picker__wrapper{
  border: 1px solid hsl(0, 0%, 80%);
  padding: 4px;
  border-radius: 4px;
  color: #111;
  background: #fff;
}
#createOrder .inError > .react-date-picker__wrapper {
  border: 1px solid red;
}
#createOrder .react-date-picker__wrapper svg, #LogisticForm .react-date-picker__wrapper svg{
  stroke: #ddd;
}
#createOrder .react-date-picker__wrapper input, #LogisticForm .react-date-picker__wrapper input {
  color: #111;
}
#createOrder .m8{
  margin: 8px 1%;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #333333;
}

.link:hover {
  color: #c38464;
  text-decoration: underline;
}

.btn-link:hover {
  background-color: #905739;
  text-decoration: underline;
}

.expBox { transition: max-height 0.4s ease-in-out; }

.paginated-select .react-select__menu::after {
  content: 'loading more...';
  position: absolute;
  background: #FFFFFF;
  text-align: center;
  padding: 10px;
  width: 100%;
  border: 1px solid #CCC;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: -31px;
  box-sizing: border-box;
  left: 0px;
  right: 0px;
  z-index: 1021;
}

.commBody table {
  margin: auto;
}

.GmHvr {
  text-decoration: none;
  cursor: pointer;
  color: #c38464;
}

.GmHvr:hover {
  text-decoration: underline;
  color: #905739;
}

.toggle.mobile{
  --width: 5rem;
  --height: 1.25rem;
}

.toggle.desktop{
  --width: 5.625rem;
  --height: 1.875rem;
}

.toggle {
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 1.25rem;
  cursor: pointer;
}

.toggle input {
  display: none;
}
.dprPicker .react-date-picker__clear-button{
  display: none;
}
.dprPicker .react-date-picker__calendar-button{
  padding:2px 0px;
}

input[disabled], select[disabled] {
  cursor: not-allowed;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #C73C3C;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--height));
  height: calc(var(--height));
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #3CC76A;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  display: flex;
  justify-content: start;
  align-items: center;
  left: -10px;
  width: 100%;
  height: 100%;
  font-size: 11px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  color: #ffffff;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  /* padding-left: 16px; */
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  color: #ffffff;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.toggle input:checked~.labels::after {
  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}

textarea {
  font-family: 'Rubik';
}

.scrollableOptions .react-select__value-container {
  overflow-y: scroll;
  display: flex;
  flex-wrap: nowrap;
  max-width: 25rem;
}

.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

input#search-component:focus-visible {
  outline: 0px;
}

.bgPo5 {
  background-color: rgba(243, 60, 5, 0.13);
}
.bgPo5o {
  background-color: rgba(243, 60, 5, 0.09);
}

.bgPo8 {
  background-color: rgba(243, 61, 5, 0.36);
}

.gm-button-elevate:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-button-elevate:hover.bgPo5 {
  background-color: rgba(243, 61, 5, 0.36);
}

.gm-button-elevate:hover .bgPo8 {
  background-color: rgba(243, 61, 5, 1);
}

/* Elevate Card */

.gm-elevated-card {
  position: relative;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 1px 1px 0px;
  --show-action: 0;
}

.gm-elevated-card:hover, .gm-elevated-card:active, .gm-elevated-card:focus-within {
  cursor: pointer;
  --show-action: 1;
  box-shadow: rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 8px 16px -6px;
  transition: box-shadow 300ms ease 0s;
  transition-duration: 300ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: box-shadow;
}

/* Solid Gray Button */

.gm-solid-gray-button {
  appearance: none;
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

/* Solid Button */

.gm-solid-button {
  appearance: none;
  background-color: #c38464;
  border: 0.125rem solid #c38464;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-button:disabled, .gm-solid-gray-button:disabled {
  /* pointer-events: none; */
  cursor: not-allowed;
  background-color: #AAAAAA;
  border-color: #AAAAAA;
}

.gm-solid-button:hover, .gm-solid-gray-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  /* background-color: #905739;
  border: 2px solid #905739; */
  transform: translateY(-2px);
}

.gm-solid-button:active, .gm-solid-gray-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* Outline Button */

.gm-outline-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #c38464;
  box-sizing: border-box;
  color: #c38464;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-button:disabled {
  pointer-events: none;
}

.gm-outline-button.disabled {
  pointer-events: none;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-button:hover {
  color: #fff;
  background-color: #c38464;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  transform: translateY(-2px);
}

.gm-outline-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
}

.gm-outline-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* outline danger button */

.gm-outline-danger-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #F33C05;
  box-sizing: border-box;
  color: #F33C05;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-danger-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-danger-button:hover {
  color: #fff;
  background-color: #F33C05;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  transform: translateY(-2px);
}

.gm-outline-danger-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
}

.gm-outline-danger-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* solid danger button */

.gm-solid-danger-button {
  appearance: none;
  background-color: #F33C05;
  border: 2px solid #F33C05;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-danger-button.disabled {
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
  cursor: not-allowed;
  background-color: #AAAAAA;
}

.gm-solid-danger-button:hover {
  color: #fff;
  background-color: #F33C05;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  transform: translateY(-2px);
}

.gm-solid-danger-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
}

.gm-solid-danger-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* solid success button */

.gm-solid-success-button {
  appearance: none;
  background-color: #0F993B;
  border: 2px solid #0F993B;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-success-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #FFFFFF;
  background-color: #AAAAAA;
}

.gm-solid-success-button:hover {
  color: #fff;
  background-color: #0F993B;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  transform: translateY(-2px);
}

.gm-solid-success-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
}

.gm-solid-success-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* outline success button */

.gm-outline-success-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #0F993B;
  box-sizing: border-box;
  color: #0F993B;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 0.625rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-success-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-success-button:hover {
  color: #fff;
  background-color: #0F993B;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
  transform: translateY(-2px);
}

.gm-outline-success-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0.5rem 1rem;
}

.gm-outline-success-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.bdfB2px {
  backdrop-filter: blur(2px);
  background-image: linear-gradient(to top, rgba(247,246,244,0), rgba(247,246,244,1));
  -webkit-backdrop-filter: blur(2px);
}

.gm-progress-grad {
  background: linear-gradient(45deg, #32e66c, #0F993B);
}

.gm-dropdown {
    box-shadow: 0 0 0 1px rgba(17,20,24,.1), 0 0.125rem 0.25rem rgba(17,20,24,.2), 0 0.5rem 1.5rem rgba(17,20,24,.2);
    display: inline-block;
    transform: scale(1);
    background: #fff;
    border-radius: 2px;
    color: #1c2127;
    list-style: none;
    margin: 0;
    min-width: 11.25rem;
    padding: 0.3125rem;
    text-align: left;
}

.gm-dropdown-element {
  align-items: flex-start;
  border-radius: 2px;
  color: inherit;
  display: flex;
  flex-direction: row;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gm-dropdown-element:hover {
  background-color: rgba(143,153,168,.15);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.gm-shadow-elevate-button {
  box-shadow: 0 0 0 1px rgba(17,20,24,.1), 0 0.125rem 0.25rem rgba(17,20,24,.2), 0 0.5rem 1.5rem rgba(17,20,24,.2);
}

.activePassive:hover{
 opacity: 1;
 pointer-events: none;
}
.activePassive:active{
  pointer-events: none;
  opacity: 0;
 }

@media only screen and (max-width: 600px) {
  #search-component::placeholder {
    font-size: 12px;
  }
}

@media print {
  .page_break {
		clear: both;
		page-break-after: always;
		break-after: page;
		padding: 5px;
	}
  .print_hide{
    display: none;
  }
}

.highcharts-tooltip {
  pointer-events: auto !important;
}
.highcharts-credits{
  display: none !important;
}

