.load-wrapp .line:nth-last-child(1) {
	animation: loadingC .6s .1s linear infinite;
} 
.load-wrapp .line:nth-last-child(2) {
	animation: loadingC .6s .2s linear infinite;
} 
.load-wrapp .line:nth-last-child(3) {
	animation: loadingC .6s .3s linear infinite;
} 
@keyframes loadingC {
	0% {transform: translate(0,0);} 
	50% {transform: translate(0,15px);} 
	100% {transform: translate(0,0);} 
}

.ghost-card-pulsate-drk {
	background: linear-gradient(-45deg, #DDDDDD, rgba(3, 19, 1, 0.5), #DDDDDD, rgba(3, 19, 1, 0.5));
	background-size: 400% 400%;
	-webkit-animation: Gradient 2.25s ease infinite;
	-moz-animation: Gradient 2.25s ease infinite;
	animation: Gradient 2.25s ease infinite;
}
@-webkit-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }
@-moz-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } } 
@keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }

.geniemode-ai-text {
    font-weight: bold;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background: linear-gradient(-45deg, #EEEEEE, #2A2A2A, #EEEEEE, #2A2A2A);
    background-size: 400% 400%;
    -webkit-animation: Gradient 2.25s ease infinite;
    -moz-animation: Gradient 2.25s ease infinite;
    animation: Gradient 2.25s ease infinite;
  }

  .fadeInUp08{
	animation-name: fadeInUp08;
	animation-duration:0.7s;
	animation-fill-mode:both;
	animation-iteration-count: 1;
  	-webkit-animation-iteration-count: 1;
  }
  @-webkit-keyframes fadeInUp08 {0% {opacity: 0; transform: translate3d(0,50%,0); } to {opacity: 0.8; transform: none} }
  @keyframes fadeInUp08 {0% {opacity: 0; transform: translate3d(0,50%,0);} to {opacity: 0.8; transform: none;} } 